@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	margin: 0;
	padding: 0;
	box-sizing: content-box;
	font-family: "Poppins", sans-serif;
	text-decoration: none;
}

::selection{
	background-color: rgba(145, 226, 253, 0.938);
	color: #212121;
}

